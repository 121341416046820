/**
 * A simple cookie checker manager. This object is a singleton.
 *
 * @class FcaCookieChecker
 * @singleton
 */
window.FcaCookieChecker = (function() {
	"use strict";

	return {
		initialized: false,
		local_storage: [],
		session_storage: [],

		cookieGroups: {
			NECESSARY_COOKIES: 'C0001',
			PERFORMANCE_COOKIES: 'C0002',
			FUNCTIONAL_COOKIES: 'C0003',
			TARGETING_COOKIES: 'C0004',
			SOCIAL_MEDIA_COOKIES: 'C0005',
		},

		groupConsents: [
			{group: 'C0001', active: true},
			{group: 'C0002', active: false},
			{group: 'C0003', active: false},
			{group: 'C0004', active: false},
			{group: 'C0005', active: false},
		],

		customCookieList: [
			// NECESSARY_COOKIES
			{name: 'OptanonConsent', group: 'C0001'},
			{name: 'OptanonAlertBoxClosed', group: 'C0001'},
			{name: 'brd-region', group: 'C0001'},
			{name: 'dealersWithChat', group: 'C0001'},
			{name: 'userId', group: 'C0001'},
			{name: 'previousSearchs', group: 'C0001'},
			{name: 'affiliateEncryptedParam', group: 'C0001'},
			{name: 'affiliateEaProgram', group: 'C0001'},
			{name: 'persistentID', group: 'C0001'},
			{name: 'dealerId', group: 'C0001'},
			{name: 'dealer-lock', group: 'C0001'},
			{name: 'dealerProvince', group: 'C0001'},
			{name: 'Lead', group: 'C0001'},
			{name: 'distance', group: 'C0001'},
			{name: 'applyForFinancingParameters', group: 'C0001'},
			{name: 'campaignCode', group: 'C0001'},
			{name: 'trimUpsell', group: 'C0001'},
			{name: 'affiliateLogoSource', group: 'C0001'},
			{name: 'contactThisDealerParameter', group: 'C0001'},
			{name: 'appointment-object', group: 'C0001'},
			{name: 'brd-location', group: 'C0001'},
			{name: 'brd-province', group: 'C0001'},
			{name: 'fca-preferred-dealer', group: 'C0001'},
			{name: 'offers-region', group: 'C0001'},
			{name: 'offers_region', group: 'C0001'},
			{name: 'G_ENABLED_IDPS', group: 'C0001'},
			{name: 'custom-order.configurations', group: 'C0001', dynamic: true},
		],
		localStorageCookieList: [
			// NECESSARY COOKIES
			{name: 'conditionalTrigger-', group: 'C0001', dynamic: true},
			{name: 'countrySelector', group: 'C0001'},
			// FUNCTIONAL_COOKIES
			{name: 'build-and-price-alerts', group: 'C0003'},
			{name: 'cookieBannerUpdated', group: 'C0003'},
			{name: 'countrySelector', group: 'C0003'},
			{name: 'fcaPersonalizationScenario_2', group: 'C0003'},
			{name: 'fcaPersonalizationScenario_2_1', group: 'C0003'},
			{name: 'fcaPersonalizationScenario_2_2', group: 'C0003'},
			{name: 'fca-ua-builtVehiclesPricing', group: 'C0003'},
			{name: 'fca-ua-builtVehiclesPricing-lastCalculatedDate', group: 'C0003'},
			{name: 'fca-ua-custom-order-vehicles', group: 'C0003'},
			{name: 'fillTheFunnelVlpTrigger', group: 'C0003'},
			{name: 'savedCustomOrderVehicle', group: 'C0003'},
			{name: 'fca-alfaromeo-disableAlerts', group: 'C0003'},
			{name: 'testDriveDelay', group: 'C0003'},
			{name: 'savedBanner', group: 'C0003'},
			{name: 'fca-ua-viewed-vehicles-no-user', group: 'C0003'},
			{name: 'savedCustomOrderVehicle', group: 'C0003'},
			{name: 'savedBanner', group: 'C0003'},
			{name: 'testDriveDelay', group: 'C0003'},
			{name: 'alfaDealerEventDelay', group: 'C0003'},
			{name: 'fca-alfaromeo-disableAlerts', group: 'C0003'},
		],
		sessionStorageCookieList: [
			// NECESSARY COOKIES
			{name: 'appointment-object', group: 'C0001'},
			{name: 'affiliateId', group: 'C0001'},
			{name: 'affiliateEncryptedParam', group: 'C0001'},
			{name: 'affiliateEaProgram', group: 'C0001'},
			{name: 'dealerId', group: 'C0001'},
			{name: 'campaignCode', group: 'C0001'},
			{name: 'dealerProvince', group: 'C0001'},
			{name: 'build-and-price.configurations-', group: 'C0001', dynamic: true},
			{name: 'dealerProvince', group: 'C0001'},
			{name: 'dealer-lock', group: 'C0001'},
			{name: 'persistentID', group: 'C0001'},
			{name: 'trimUpsell', group: 'C0001'},
			{name: 'applyForFinancingParameters', group: 'C0001'},
			{name: 'contactThisDealerParameter', group: 'C0001'},
			{name: 'C_NID-vehicle-inventory-list-url', group: 'C0001'},
			{name: 'customOrder', group: 'C0001'},
			{name: 'fca-ua-verified', group: 'C0001'},
			{name: 'fca-ua-user', group: 'C0001'},
			{name: 'fca-ua-inventory-vehicles', group: 'C0001'},
			{name: 'fca-ua-built-vehicles', group: 'C0001'},
			{name: 'fca-ua-custom-order-vehicles', group: 'C0001'},
			{name: 'fca-ua-viewed-vehicles', group: 'C0001'},
			// FUNCTIONAL COOKIES
			{name: 'affiliateLogoSource', group: 'C0003'},
			{name: 'lastUpdatedDates', group: 'C0003'},
			{name: 'stickyToolBarAppearedOnce', group: 'C0003'},
			{name: 'buy-', group: 'C0003', dynamic: true},
			{name: 'fca-loyalty-offers', group: 'C0003'},
			{name: 'animated-hero-', group: 'C0003', dynamic: true},
			// TARGETING COOKIES
			{name: 'vendor', group: 'C0004'},
			{name: 'dealer-url', group: 'C0004'},
		],

		/**
		 * @returns Returns true if OneTrustApi is loaded and ready
		 */
		isOneTrustInitialized: function() {
			return window.OneTrust != null && this.initialized;
		},

		/**
		 * Will calls the callback input function if OneTrustApi is loaded and ready, else call the function to add the callback to a list
		 * @param {function} callback The function to execute if OneTrustApi is ready
		 * @param {string} callbackId The id of the callback
		 * @param {boolean} keepAfterExecute Set to true if the callbacks needs to be called after each consent change
		 */
		onOneTrustAPIready: function(callback, callbackId, keepAfterExecute) {
			if (typeof callback === 'function') {
				if (this.isOneTrustInitialized()) callback();
				else this.addFunctionToCallbackList(callback, callbackId, keepAfterExecute);
			}
		},

		//#region CALLBACK FUNCTIONS MANAGEMENT
		/**
		 * Add the callback input to a list to be executed later (when OneTrustApi is ready or when the consent change if `keepAfterExecute` was set to true).
		 * Will check with the id that the callback isn't already in the list
		 * @param {function} callback The function to execute if OneTrustApi is ready
		 * @param {string } id The id of the callback
		 * @param {boolean} keepAfterExecute Set to true if the callbacks needs to be called after each consent change
		 */
		addFunctionToCallbackList: function(callback, id, keepAfterExecute) {
			if (!window.FcaOnetrustListCallback) window.FcaOnetrustListCallback = [];
			if(window.FcaOnetrustListCallback.some(item => item.id === id)) {

				window.FcaOnetrustListCallback.map(item => {
					if(item.id === id) {
						item.callback = callback;
						item.keepAfterExecute = keepAfterExecute;
					}
				});
			} else {
				window.FcaOnetrustListCallback.push({id, keepAfterExecute, callback});
			}
		},

		/**
		 * Will call the callbacks functions.
		 * If `keepAfterExecute` was set to false then remove the callback from the list after the call
		 */
		executeCallbacks: function() {
			window.FcaOnetrustListCallback.forEach((item, index) => {
				item.callback();
				if (!item.keepAfterExecute) window.FcaOnetrustListCallback.splice(index, 1);
			});
		},
		//#endregion

		sendAnalyticsData: function() {
			// this.manageAnalyticsUserConsentChanges();
			if(!this.initialized) this.manageModalsAnalytics();
			this.initialized = true;
		},

		manageAnalyticsUserConsentChanges: function() {
			gtag("consent", "update", {
				analytics_storage: this.getAnalyticsConsentType(this.cookieGroups.PERFORMANCE_COOKIES),
				ad_storage: this.getAnalyticsConsentType(this.cookieGroups.TARGETING_COOKIES),
				functionality_storage: this.getAnalyticsConsentType(this.cookieGroups.FUNCTIONAL_COOKIES),
				ad_user_data: this.getAnalyticsConsentType(this.cookieGroups.TARGETING_COOKIES),
				ad_personalization: this.getAnalyticsConsentType(this.cookieGroups.TARGETING_COOKIES),
			});
		},

		manageModalsAnalytics: function() {
			let eventLabelPrefix = "cookie-banner-";

			const bannerElement = document.getElementById("onetrust-banner-sdk");
			const preferenceCenterElement = document.getElementById("onetrust-pc-sdk");

			// BANNER ELEMENTS
			if(bannerElement) {
				// Banner CTAs (e.g.: "Customize", "Accept All")
				const buttonElements = bannerElement.getElementsByTagName("button");
				if(buttonElements.length) {
					Array.from(buttonElements).forEach(element => {
						this.listenEventAndSendAnalytics(element, "click", `${eventLabelPrefix}${element.innerText.toLocaleLowerCase()}`);
					})
				}

				// Banner Links (e.g.: "Privacy Policy")
				const bannerDescriptionElement = document.getElementById("onetrust-policy-text");
				const bannerLinkElements = bannerDescriptionElement.getElementsByTagName("a");
				if(bannerLinkElements.length) {
					Array.from(bannerLinkElements).forEach(element => {
						this.listenEventAndSendAnalytics(element, "click", `${eventLabelPrefix}${element.innerText.toLocaleLowerCase()}`);
					})
				}
			}

			// PREFERENCE CENTER ELEMENTS
			if(preferenceCenterElement) {
				eventLabelPrefix = "cookie-pop-up-";

				// Preference center Cross close button
				const closeButtonElement = document.getElementById("close-pc-btn-handler");
				if(closeButtonElement) this.listenEventAndSendAnalytics(closeButtonElement, "click", `${eventLabelPrefix}close`);

				// Preference center Links (e.g.: "Cookie Notice")
				const pcDescriptionElement = document.getElementById("ot-pc-desc");
				const pcLinkElements = pcDescriptionElement.getElementsByTagName("a");
				if(pcLinkElements.length) {
					Array.from(pcLinkElements).forEach(element => {
						this.listenEventAndSendAnalytics(element, "click", `${eventLabelPrefix}${element.innerText.toLocaleLowerCase()}`);
					})
				}

				// Preference center Toggle buttons (e.g.: "Performance Cookies on/off")
				const toggleElements = preferenceCenterElement.getElementsByClassName("ot-tgl");
				if(toggleElements.length) {
					Array.from(toggleElements).forEach(element => {
						const text = element.getElementsByClassName("ot-label-txt")[0].innerText.toLowerCase();
						element.addEventListener('change', () => {
							let state = "off";
							const inputElements = element.getElementsByTagName("input");
							if(inputElements.length && inputElements[0].checked) state = 'on';
							this.sendAnalytics(`${eventLabelPrefix}${text}-${state}`);
						})
					})
				}

				// Preference center Details Sections and CTAS (e.g.:" + Strictly Necessary Cookies", "Accept All")
				const pcButtonElements = preferenceCenterElement.getElementsByTagName("button");
				if(pcButtonElements.length) {
					Array.from(pcButtonElements).forEach(element => {
						element.addEventListener("click", () => {
							let text = element.innerText.toLowerCase();
							if(text) {
								dataLayer.push({eventCategory: "app-suite-" + window.FCA_SITES_CONFIG.pageCode,eventAction: window.location.href,event: "gaevent", eventLabel:"cookie-pop-up-"+element.innerText.toLowerCase()});
							} else if(element.getAttribute("aria-expanded")) {
								const text = element.parentElement.getElementsByClassName("ot-cat-header")[0].innerText.toLowerCase();
								const state = element.ariaExpanded === "true" ? "close": "expand";
								this.sendAnalytics(`${eventLabelPrefix}${text}-${state}`);

							}
						})
					})


				}
			}

			// FOOTER LINK
			const cookieSettingsLinkElements = document.getElementsByClassName("ot-sdk-show-settings");
			if(cookieSettingsLinkElements.length) {
				Array.from(cookieSettingsLinkElements).forEach(element => {
					this.listenEventAndSendAnalytics(element, "click", `cookie-settings-open`);
				})
			}
		},

		listenEventAndSendAnalytics: function(element, eventToListen, eventLabel) {
			element.addEventListener(eventToListen, () => {
				this.sendAnalytics(eventLabel);
			})
		},

		sendAnalytics: function(eventLabel) {
			dataLayer.push({eventCategory: "app-suite-" + window.FCA_SITES_CONFIG.pageCode, eventAction: window.location.href, event: "gaevent", eventLabel});
		},


		getAnalyticsConsentType: function(groupCode) {
			return this.isGroupActivated(this.getGroupConsent(groupCode)) ? 'granted' : 'denied';
		},

		//#region Cookie Display Name functions
		/**
		 * @param {String} displayName - window.OneTrust.GetDomainData().Groups[x].Hosts[x].DisplayName
		 * @returns {Promise<boolean>} Returns true if the input cookie name is activated
		 */
		isCookieActivated_ByDisplayName: function(displayName) {
			return new Promise((resolve) => {
				if (window.OneTrust != null) resolve(this.isOneTrustGroupActivated(this.getGroupByCookieName(displayName)));
				else this.onOneTrustAPIready(() => resolve(this.isOneTrustGroupActivated(this.getGroupByCookieName(displayName))), `isCookieActivatedByDisplayName-${displayName}`, false);
			});
		},

		/**
		 * /!\ That function shouldn't be use directly - Call isCookieActivated_ByDisplayName() to make sure OneTrust api is ready /!\
		 * @param {String} displayName - window.OneTrust.GetDomainData().Groups[x].Hosts[x].DisplayName
		 * @returns {Object} Returns the group of the cookie
		 */
		getGroupByCookieName: function(displayName) {
			if (!window.OneTrust.GetDomainData()) return [];
			const group = window.OneTrust.GetDomainData().Groups.filter((group) => group.Hosts.find(host => host.DisplayName === displayName));
			return group && group.length ? group[0] : [];
		},
		//#endregion

		//#region ADD TO STORAGE FUNCTIONS
		/**
		 * Call the function `addStorage` if OneTrustAfpi is ready, else add the function to the callback list to be called when OneTrustAfpi will be ready
		 * @param {string} name Name of the local storage item
		 * @param {string} content Content of the local storage item
		 */
		addLocalStorage: function(name, content) {
			return new Promise((resolve) => {
				if (this.isOneTrustInitialized()) resolve(this.addStorage(name, content, true));
				else this.onOneTrustAPIready(() => resolve(this.addStorage(name, content, true)), name, true);
			});
		},

		/**
		 * Call the function `addStorage` if OneTrustAfpi is ready, else add the function to the callback list to be called when OneTrustAfpi will be ready
		 * @param {string} name Name of the session storage item
		 * @param {string} content Content of the session storage item
		 */
		addSessionStorage: function(name, content) {
			return new Promise((resolve) => {
				if (this.isOneTrustInitialized()) resolve(this.addStorage(name, content, false));
				else this.onOneTrustAPIready(() => resolve(this.addStorage(name, content, false)), name, true);
			});
		},

		/**
		 * Will add the item to the storage if it's group is activated.
		 * Will also add the function to the callback list to be able to add it to the storage if the user that first refused cookies, decided to activate them
		 * @param {string} name Name of the storage item
		 * @param {string} content Content of the storage item
		 * @param {boolean} isLocalStorage if set to true then will add the item to the local storage, else will add it to the session storage
		 */
		addStorage: function(name, content, isLocalStorage) {
			const storageCookieList = isLocalStorage ? this.localStorageCookieList : this.sessionStorageCookieList;
			const storage = isLocalStorage ? window.localStorage : sessionStorage;
			const storageElement = storageCookieList.find(item => item.dynamic ? name.includes(item.name) : item.name === name);
			if (!storageElement) return;
			const groupConsent = this.getGroupConsent(storageElement.group);
			if (groupConsent && groupConsent.active) storage.setItem(name, content);
			this.addFunctionToCallbackList(() => (this.addStorage(name, content, isLocalStorage)), name, true);
		},
		/**
		 * Will get the item from the storage if it exists.
		 * @param {string} name Name of the storage item
		 * @param {boolean} isLocalStorage Name of the storage item
		 * @return {promise<string|null>} value of the storage
		 */
		getStorage: function(name, isLocalStorage) {
			const storage = isLocalStorage ? window.localStorage : sessionStorage;
			if (this.isOneTrustInitialized()) {
				return new Promise((resolve) => resolve(storage.getItem(name)));
			} else {
				return new Promise((resolve) => {
					this.addFunctionToCallbackList(() => {
						resolve(storage.getItem(name));
					}, name, false)
				});
			}
		},

		/**
		 * Will get the item from the storage if it exists.
		 * @param {string} name Name of the storage item
		 * @return {promise<string|null>} value of the storage
		 */
		getLocalStorage: function(name) {
			return this.getStorage(name, true);
		},

		/**
		 * Will get the item from the storage if it exists.
		 * @param {string} name Name of the storage item
		 * @return {promise<string|null>} value of the storage
		 */
		getSessionStorage: function(name) {
			return this.getStorage(name, false);
		},

		/**
		 * @param {string} name Name of the cookie item
		 * @param {string } content Content of the cookie item
		 */
		addCookie: function(name, content) {
			const storageElement = this.customCookieList.find(item => item.name === name);
			if (!storageElement) return;
			const groupConsent = this.getGroupConsent(storageElement.group);
			if (groupConsent && groupConsent.active) {
				const path = (storageElement.path) ? `; path=${storageElement.path}` : "";
				const domain = (storageElement.domain) ? `; domain=${storageElement.domain}` : "";
				const expiration = (storageElement.expiration) ? `; expires=${storageElement.expiration}` : "";
				document.cookie = `${name}=${content}${path}${domain}${expiration}`
			}
			this.addFunctionToCallbackList(() => (this.addCookie(name, content)), name, true);
		},

		getCookie: function(name) {
			if (this.isOneTrustInitialized()) {
				return new Promise((resolve) => {
					resolve(this.parseCookie(name))
				});
			} else {
				return new Promise((resolve) => {
					this.addFunctionToCallbackList(() => {
						resolve(this.parseCookie(name))
					}, name, false)
				});
			}
		},

		/**
		 * @param {string} cName Name of the cookie item
		 * @return {string} return  the cookie data
		 */
		parseCookie: function(cName) {
			const name = cName + "=";
			const cDecoded = decodeURIComponent(document.cookie);
			const cArr = cDecoded.split('; ');
			const result = cArr.find(val => val.indexOf(name) === 0);
			return result ? result.substring(name.length) : '';
		},
		//#endregion

		/**
		 * @param {String} groupCode
		 * @returns {{group: string, active: boolean} | undefined} Returns the cookie group assiociated to the input group code
		 */
		getGroupConsent: function(groupCode) {
			return this.groupConsents.find(item => item.group === groupCode);
		},

		/**
		 * Get the value from a local array of group consents (to prevent using a promise because of calling the OneTrustApi)
		 * @param {{group: string, active: boolean}} group - element of `groupConsents`
		 * @returns {boolean} Returns true if the group is activated
		 */
		isGroupActivated: function(group) {
			return group ? group.active : false;
		},

		/**
		 * Get info from OneTrustApi - Needs to use a promise to be sure OneTrustApi is ready
		 * @param {Object} group - window.OneTrust.GetDomainData().Groups[x]
		 * @returns {boolean} Returns true if the group is activated
		 */
		isOneTrustGroupActivated: function(group) {
			if (!group) return false;
			const activeGroupIdList = window.OnetrustActiveGroups.split(",");
			return activeGroupIdList.length && activeGroupIdList.some((activatedGroupId) => activatedGroupId === group.OptanonGroupId);
		},

		/**
		 * Get info from OneTrustApi
		 * @param {String} groupId - window.OneTrust.GetDomainData().Groups[x].OptanonGroupId
		 * @returns {Object} Returns the group of the group id
		 */
		getOneTrustGroupByGroupId: function(groupId) {
			const group = window.OneTrust.GetDomainData().Groups.filter((group) => group.OptanonGroupId === groupId);
			return group && group.length ? group[0] : [];
		},

		/**
		 * Sets the values of `groupConsents` with the consent of the user for each cookie group. Will be updated after each consent changes
		 */
		setCookieGroupConsentsValues: function() {
			this.groupConsents.find(consent => consent.group === this.cookieGroups.PERFORMANCE_COOKIES).active = this.isOneTrustGroupActivated(this.getOneTrustGroupByGroupId(this.cookieGroups.PERFORMANCE_COOKIES));
			this.groupConsents.find(consent => consent.group === this.cookieGroups.FUNCTIONAL_COOKIES).active = this.isOneTrustGroupActivated(this.getOneTrustGroupByGroupId(this.cookieGroups.FUNCTIONAL_COOKIES));
			this.groupConsents.find(consent => consent.group === this.cookieGroups.TARGETING_COOKIES).active = this.isOneTrustGroupActivated(this.getOneTrustGroupByGroupId(this.cookieGroups.TARGETING_COOKIES));
			this.groupConsents.find(consent => consent.group === this.cookieGroups.SOCIAL_MEDIA_COOKIES).active = this.isOneTrustGroupActivated(this.getOneTrustGroupByGroupId(this.cookieGroups.SOCIAL_MEDIA_COOKIES));
		},
		/**
		 * remove all cookie that are not approved with the current consent state
		 */
		removeCookies: function() {
			const cookieList = document.cookie.split("; ");
			cookieList.forEach(cookie => {
				const name = cookie.split("=")[0];
				const cookieItem = this.customCookieList.find(item => item.dynamic ? name.includes(item.name) : item.name === name);
				if (!cookieItem) {
					document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT`
				}
			})
			this.customCookieList.forEach(item => {
				if (item.group !== this.cookieGroups.NECESSARY_COOKIES) {
					const groupConsent = this.getGroupConsent(item.group);
					if (!groupConsent.active) {
						document.cookie = `${item.name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT`
					}
				}
			})
		},
		/**
		 * remove all session storage that are not approved with the current consent state
		 */
		removeSessionStorage: function() {
			const sessionStoragekeys = Object.keys(sessionStorage);
			sessionStoragekeys.forEach(key => {
				const cookieItem = this.sessionStorageCookieList.find(item => item.dynamic ? key.includes(item.name) : item.name === key);
				if (!cookieItem) sessionStorage.removeItem(key);
				else if (cookieItem.group !== this.cookieGroups.NECESSARY_COOKIES) {
					const groupConsent = this.getGroupConsent(cookieItem.group);
					if (!groupConsent.active && sessionStorage.getItem(key)) {
						sessionStorage.removeItem(key);
					}
				}
			});
		},
		/**
		 * remove all local storage that are not approved with the current consent state
		 */
		removeLocalStorage: function() {
			const localStoragekeys = Object.keys(window.localStorage);
			localStoragekeys.forEach(key => {
				const cookieItem = this.localStorageCookieList.find(item => item.dynamic ? key.includes(item.name) : item.name === key);
				if (!cookieItem) window.localStorage.removeItem(key);
				else if (cookieItem.group !== this.cookieGroups.NECESSARY_COOKIES) {
					const groupConsent = this.getGroupConsent(cookieItem.group);
					if (!groupConsent.active && window.localStorage.getItem(key)) {
						window.localStorage.removeItem(key);
					}
				}
			});
		},

		/**
		 * Will be called after OneTrust initialization and after every consent changes.
		 * Checks all storage items (session and local) and remove item if the group isn't activated
		 * Calls `executeCallbacks` function
		 */
		userConsentChanged: function() {
			this.removeCookies();
			this.removeSessionStorage();
			this.removeLocalStorage();
			this.executeCallbacks();
		},
	};
})();
